import React from 'react';

import {
  FaFile,
  FaFileAlt,
  FaBuilding,
  FaClipboardList,
  FaUserSlash,
  FaEdit,
  FaListAlt,
  FaHistory,
  FaClipboardCheck,
  FaFolder,
  FaIdCard,
  FaTrash,
  FaFileContract,
  FaPersonBooth
} from 'react-icons/fa';

interface IconProps {
  icon: string;
  color?: string;
  size?: number;
  onClick?(): any;
  className?: string;
}

const Icon: React.FC<IconProps> = ({ icon, color = '#444', size = 15, ...rest }) => {
  const icons = new Map([
    ['FaClipboardList', <FaClipboardList color={color} size={size}  {...rest} />],
    ['FaBuilding', <FaBuilding color={color} size={size}  {...rest} />],
    ['FaFile', <FaFile color={color} size={size} {...rest} />],
    ['FaFileAlt', <FaFileAlt color={color} size={size} {...rest} />],
    ['FaUserSlash', <FaUserSlash color={color} size={size} {...rest} />],
    ['FaEdit', <FaEdit color={color} size={size} {...rest} />],
    ['FaListAlt', <FaListAlt color={color} size={size} {...rest} />],
    ['FaHistory', <FaHistory color={color} size={size} {...rest} />],
    ['FaClipboardCheck', <FaClipboardCheck color={color} size={size} {...rest} />],
    ['FaFolder', <FaFolder color={color} size={size} {...rest} />],
    ['FaIdCard', <FaIdCard color={color} size={size} {...rest} />],
    ['FaTrash', <FaTrash color={color} size={size} {...rest} />],
    ['FaFileContract',  <FaFileContract color={color} size={size} {...rest} />],
    ['FaPersonBooth',  <FaPersonBooth color={color} size={size} {...rest} />]
  ]);

  return <>{icons.has(icon) ? icons.get(icon) : null}</>;
};

export default Icon;
