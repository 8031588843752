import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import api from 'src/services/axios';
import { toast } from 'react-toastify';
import SupervisorForm from '../../../components/SupervisorForm';
import SupervisorTable from '../../../components/SupervisorTable';


interface Supervisor {
  id: number;
  name: string;
  position: string;
}

const CompaniesSupervisorForm: React.FC = () => {
  const params = useParams();
  const [supervisors, setSupervisors] = useState<Supervisor[]>([]);

  useEffect(() => {
    fetchSupervisors();
  }, []);

  // Função para buscar os supervisores na API
  const fetchSupervisors = async () => {
    try {
      const response = await api.get(`/supervisors/companies?company_id=${params.id}`);
      setSupervisors(response.data);
    } catch (error) {
      toast.error('Erro ao buscar supervisores!');
    }
  };

  // Função para adicionar supervisor
  const handleAddSupervisor = async (data: { name: string; position: string }) => {
    try {
      const response = await api.post('/supervisors/companies', { ...data, company_id: params.id });
      setSupervisors([...supervisors, response.data]);
      toast.success('Supervisor adicionado com sucesso!');
    } catch (error) {
      toast.error('Erro ao adicionar supervisor!');
    }
  };

  // Função para deletar supervisor
  const handleDeleteSupervisor = async (id: number) => {
    try {
      await api.delete(`/supervisors/${id}`);
      setSupervisors(supervisors.filter((supervisor) => supervisor.id !== id));
      toast.success('Supervisor removido com sucesso!');
    } catch (error) {
      toast.error('Erro ao remover supervisor!');
    }
  };

  return (
    <Container>
      {/* Formulário de Adicionar Supervisor */}
      <SupervisorForm onAddSupervisor={handleAddSupervisor} />

      {/* Tabela de Supervisores */}
      <SupervisorTable supervisors={supervisors} onDeleteSupervisor={handleDeleteSupervisor} />
    </Container>
  );
};

export default CompaniesSupervisorForm;
