import React from 'react';
import { Button, Card, Table } from 'react-bootstrap';

interface Supervisor {
  id: number;
  name: string;
  position: string;
}

interface SupervisorTableProps {
  supervisors: Supervisor[];
  onDeleteSupervisor: (id: number) => void;
}

const SupervisorTable: React.FC<SupervisorTableProps> = ({ supervisors, onDeleteSupervisor }) => {
  return (
    <Card className="mt-4">
      <Card.Header as='h5'>Supervisores Cadastrados</Card.Header>
      <Card.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Nome</th>
              <th>Cargo</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {supervisors.map((supervisor, index) => (
              <tr key={supervisor.id}>
                <td>{index + 1}</td>
                <td>{supervisor.name}</td>
                <td>{supervisor.position}</td>
                <td>
                  <Button variant="danger" onClick={() => onDeleteSupervisor(supervisor.id)}>
                    Deletar
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default SupervisorTable;
