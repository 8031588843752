import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Importações do bootstrap
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

// Meus componentes
import ComplexTable from '../../../components/ComplexTable';

//services
import api from '../../../services/axios';
import Icon from '../../../components/icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const InstituicaoEducacaoList: React.FC = () => {
  const history = useHistory();

  const [data, setData] = useState([]);

  useEffect(() => {
    api.get('educational_institutions').then((response) => {
      setData(response.data);
    })
  }, []);


  return (
    <Container fluid>
      <Card  style={{boxShadow:'var(--card-bs)'}}>
        <Card.Header as="h5" className='d-flex justify-content-between align-items-center color-secondary'>
          <div>
          <i className="bi bi-stack mr-2"></i>
          Instituições de Educação
          </div>

          <Button
            variant="primary"
            onClick={() => history.push('/instituicao_educacao/novo')}
            className="button-primary"
          >
               <i className="bi bi-plus-lg mr-2"></i>
            Nova instituição
          </Button>
        </Card.Header>
        <Card.Body>
          <ComplexTable
            data={data}
            columns={[
              {
                dataField: 'cnpj',
                text: 'CNPJ',
                sort: true,
                filter: true,
              },
              {
                dataField: 'name',
                text: 'Nome',
                sort: true,
                filter: true,
              },
              {
                dataField: 'city',
                text: 'Cidade',
                sort: true,
                filter: true,
              },
              {
                dataField: 'contact',
                text: 'Contato',
                sort: true,
                filter: true,
              },
              {
                dataField: 'id',
                text: 'Ações',
                sort: false,
                headerStyle: {
                  width: '25%',
                },
                formatter: (_, row) => (
                  <>
                    <OverlayTrigger
                      key="edit"
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-right`}>
                          Editar Instituição de educação
                        </Tooltip>
                      }
                    >
                      <Icon icon="FaEdit" className=" color-secondary interaction" size={25} onClick={() => history.push(`/instituicao_educacao/${row.id}`)} />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="supervisores"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          Adicionar Supervisor
                        </Tooltip>
                      }
                    >
                      <Icon
                        icon="FaPersonBooth"
                        className="mr-4 color-secondary interaction"
                        size={25}
                        onClick={() => history.push(`/instituicao_educacao/supervisores/${row.id}`)}
                      />

                    </OverlayTrigger>
                  </>
                )
              },
            ]}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default InstituicaoEducacaoList;
