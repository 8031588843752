import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { differenceInDays, format, isSameMonth, parseISO } from 'date-fns';

import { handleGenerateReportFinancial } from '../templates/report'

// Componentes do react-bootrap
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form';

import ExportJsonExcel from 'js-export-excel'

// Componentes da aplicação
import Select from '../../../components/Select';
import InputDate from '../../../components/InputDate'
import ComplexTable from '../../../components/ComplexTable';

import api from 'src/services/axios';
import { Col, Row } from 'react-bootstrap';
import { ptBR } from 'date-fns/locale';
import { FaFilePdf, FaFilter } from 'react-icons/fa';
import { RiFileExcel2Fill } from 'react-icons/ri';

const FinancialReport: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [contracts, setContracts] = useState<any>([]);

  const [companiesOptions, setCompaniesOptions] = useState([{
    value: '',
    label: 'Todas'
  }]);

  const { handleSubmit, control, watch, getValues } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      company_id: [
        {
          value: '',
          label: 'Todas'
        },
      ],
      ignore_contracts_date: undefined,

      date_start_end_validity: undefined,
      date_end_end_validity: undefined,
      omitir_value: {
        value: false,
        label: 'Não'
      },
      showData: {
        value: '',
        label: 'Todos os registros'
      }
    }
  });

  const omitir_value = watch('omitir_value');
  const showData = watch('showData');


  const calcValueProportional = (tax: number, days: number) => ((Number(tax) / 30) * days)

  const applyRuleIsHigherEducation = (isHigherEducation: boolean, value: number) => isHigherEducation ? 0 : value

  const handleSetReport = useCallback((data) => {
    const valueForm = getValues()

    const dataActives = data.actives.map(
      (contract) => {
        const parseContract: any = {}

        const startValidity = parseISO(contract.start_validity.substring(0,10))
        const dayStartValidity = Number(format(startValidity, 'dd', { locale: ptBR }))
        const daysToCalcValueProportional = 31 - dayStartValidity

        if (contract.company.type_charge === "INTEGRAL") {

          if (isSameMonth(startValidity, valueForm.ignore_contracts_date)) {

            if (dayStartValidity < 24) {
              parseContract.integration_agent_value_company_total = contract.company.integration_agent_value
              parseContract.institution_value_company_total = applyRuleIsHigherEducation(contract.is_higher_education, contract.company.institution_value)
            } else {
              parseContract.integration_agent_value_company_total = 0
              parseContract.institution_value_company_total = 0
            }

          } else {
            parseContract.integration_agent_value_company_total = contract.company.integration_agent_value
            parseContract.institution_value_company_total = applyRuleIsHigherEducation(contract.is_higher_education, contract.company.institution_value)
          }


        } else if (contract.company.type_charge === "PROPORTIONAL") {

          if (isSameMonth(startValidity, valueForm.ignore_contracts_date)) {

            if (dayStartValidity < 24) {
              parseContract.integration_agent_value_company_total = contract.is_higher_education ?
                contract.company.integration_agent_higher_education_value :
                contract.company.integration_agent_value
              parseContract.institution_value_company_total = applyRuleIsHigherEducation(contract.is_higher_education, contract.company.institution_value)
            } else {

              parseContract.integration_agent_value_company_total = calcValueProportional(
                contract.company.integration_agent_value,
                daysToCalcValueProportional
              )
              parseContract.institution_value_company_total = applyRuleIsHigherEducation(
                contract.is_higher_education,
                calcValueProportional(
                  contract.company.institution_value,
                  daysToCalcValueProportional
                ))
            }

          } else {

            parseContract.integration_agent_value_company_total = contract.company.integration_agent_value
            parseContract.institution_value_company_total = applyRuleIsHigherEducation(contract.is_higher_education, contract.company.institution_value)
          }
        }


        return {
          ...parseContract,
          id: contract.id,
          trainee_name: contract.trainee?.name,
          trainee_primary_phone_contact: contract.trainee?.primary_phone_contact,
          insurance_number: contract.insurance_number,
          company_name: contract?.company?.company_name,
          status: contract.status ? 'ATIVO' : 'INATIVO',
          date_shutdown: '--',
          originalStatus: contract.status,
          total_value: contract.internship_scholarship_value + contract.transportation_assistance_value,
          trainee_date_birth: contract.trainee.date_birth ? format(parseISO(contract.trainee.date_birth.substring(0,10)), 'dd/MM/yyyy') : '',
          total_value_formatted: new Intl.NumberFormat('pt', {
            style: 'currency',
            currency: 'BRL'
          }).format(contract.internship_scholarship_value + contract.transportation_assistance_value),
          start_validity: format(parseISO(contract.start_validity.substring(0,10)), 'dd/MM/yyyy'),
          count_date: differenceInDays(new Date(), startValidity),
          integration_agent_value_company: contract.company.integration_agent_value,
          institution_value_company: contract.company.institution_value,
          isSuperior: contract.is_higher_education
        }
      }
    )



    const dataInactives = data.inactives.map(
      (contract) => {

        let count = '---'

        const parseContract: any = {}
        const startValidity = parseISO(contract.start_validity.substring(0,10))
        const dateShutdown = parseISO(contract.date_shutdown.substring(0,10))
        const dayDateShutdown = Number(format(dateShutdown, 'dd', { locale: ptBR }))
        const daysdifference = differenceInDays(dateShutdown, startValidity)

        if (contract.company.type_charge === "INTEGRAL") {

          if (isSameMonth(dateShutdown, startValidity)) {

            if (daysdifference < 5 && dayDateShutdown < 11) { // verificar SE e 5 mesmo
              parseContract.integration_agent_value_company_total = 0
              parseContract.institution_value_company_total = 0
            } else {
              parseContract.integration_agent_value_company_total = contract.company.integration_agent_value
              parseContract.institution_value_company_total = contract.company.institution_value
            }

          } else {

            if (dayDateShutdown < 11) {
              parseContract.integration_agent_value_company_total = 0
              parseContract.institution_value_company_total = 0
            } else {
              parseContract.integration_agent_value_company_total = contract.company.integration_agent_value
              parseContract.institution_value_company_total = contract.company.institution_value
            }
          }

        } else if (contract.company.type_charge === "PROPORTIONAL") {

          if (isSameMonth(startValidity, dateShutdown)) {

            const differenceDays = differenceInDays(dateShutdown, startValidity)

            parseContract.integration_agent_value_company_total = calcValueProportional(
              contract.company.integration_agent_value,
              differenceDays
            )

            parseContract.institution_value_company_total = calcValueProportional(
              contract.company.institution_value,
              differenceDays
            )

          } else {

            parseContract.integration_agent_value_company_total = calcValueProportional(
              contract.company.integration_agent_value,
              dayDateShutdown
            )

            parseContract.institution_value_company_total = calcValueProportional(
              contract.company.institution_value,
              dayDateShutdown
            )
          }

        }

        return {
          ...parseContract,
          id: contract.id,
          trainee_name: contract.trainee?.name,
          trainee_date_birth: contract.trainee.date_birth ? format(parseISO(contract.trainee.date_birth.substring(0,10)), 'dd/MM/yyyy') : '',
          trainee_primary_phone_contact: contract.trainee?.primary_phone_contact,
          insurance_number: contract.insurance_number,
          company_name: contract?.company?.company_name,
          date_shutdown: format(parseISO(contract.date_shutdown.substring(0,10)), 'dd/MM/yyyy'),
          status: contract.status ? 'ATIVO' : 'INATIVO',
          originalStatus: contract.status,
          total_value: contract.internship_scholarship_value + contract.transportation_assistance_value,
          start_validity: format(parseISO(contract.start_validity.substring(0,10)), 'dd/MM/yyyy'),
          total_value_formatted: new Intl.NumberFormat('pt', {
            style: 'currency',
            currency: 'BRL'
          }).format(contract.internship_scholarship_value + contract.transportation_assistance_value),
          count_date: count,
          integration_agent_value_company: contract.company.integration_agent_value,
          institution_value_company: contract.company.institution_value,
        }

      }
    )

    setContracts([...dataActives, ...dataInactives])

  }, [getValues]);



  const OnSubmit = useCallback((data) => {
    setLoading(true)
    let queryParams = new URLSearchParams();

    if (data.ignore_contracts_date) queryParams.set('ignore_contracts_date', format(data.ignore_contracts_date, 'yyyy-MM-dd'));

    if (data.date_start_end_validity) queryParams.set('date_start_end_validity', format(data.date_start_end_validity, 'yyyy-MM-dd'));
    if (data.date_end_end_validity) queryParams.set('date_end_end_validity', format(data.date_end_end_validity, 'yyyy-MM-dd'));

    if (data.company_id) {
      queryParams.set('company_id', data.company_id.reduce((acc, current) => {
        return [...acc, current.value]
      }, []));
    }

    api.get(`reports/financial?${queryParams}`).then(response => {
      handleSetReport(response.data);
    })

      .finally(() => setLoading(false))

  }, [handleSetReport]);

  const handleGenerateExcelFile = () => {

    const parseContracts = contracts.map(item => {
      return {
        name: item.trainee_name,
        trainee_date_birth: item.trainee_date_birth,
        contact: item.trainee_primary_phone_contact,
        company_name: item.company_name,
        total_value_formatted: item.total_value,
        start_validity: item.start_validity,
        date_shutdown: item.date_shutdown,
        status: item.status
      }
    })

    let option = {
      fileName: `${Date.now()}-financeiro`,
      datas: [
        {
          sheetData: parseContracts,
          sheetName: 'sheet',
          sheetFilter: ['name', 'trainee_date_birth', 'contact', 'company_name', 'total_value_formatted', 'start_validity', 'date_shutdown', 'status'],
          sheetHeader: ['Nome', 'Data de nascimento', 'Contato', 'Empresa', 'Auxílio Bolsa', 'Data do Cadastro', 'Data do desligamento', 'status'],
        }
      ]
    }


    let toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }


  useEffect(() => {
    api.get('companies').then((response) => {
      setCompaniesOptions([...companiesOptions, ...response.data.map(item => {
        return {
          value: item.id,
          label: item.company_name,
        }
      })]);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid>
      <Card style={{ boxShadow: 'var(--card-bs)' }}>
        <Card.Header as="h5" className='d-flex align-items-center color-secondary'>
          Relatório Financeiro
        </Card.Header>
        <Card.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <Form onSubmit={handleSubmit(OnSubmit)}>
            <Form.Row>
              <Controller
                as={InputDate}
                control={control}
                name="ignore_contracts_date"
                label="Ignorar os contratos ativos apartir de:"
                md="12"
              />

            </Form.Row>

            <Form.Row>

              <Controller
                as={InputDate}
                control={control}
                name="date_start_end_validity"
                label="Considerar os contratos inativos - Data inicial "
                md="6"
              />
              <Controller
                as={InputDate}
                control={control}
                name="date_end_end_validity"
                label="Considerar os contratos inativos - Data final"
                md="6"
              />
            </Form.Row>

            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name="company_id"
                label="Qual a empresa ?"
                md="12"
                options={companiesOptions}
                isMulti
              />
              <Controller
                as={Select}
                control={control}
                name="omitir_value"
                label="Omitir coluna de valores ?"
                md="12"
                options={[
                  {
                    value: true,
                    label: 'Sim'
                  },
                  {
                    value: false,
                    label: 'Não'
                  }
                ]}
              />
              <Controller
                as={Select}
                control={control}
                name="showData"
                label="Mostrar no PDF ?"
                md="12"
                options={[
                  {
                    value: '',
                    label: 'Todos os registros'
                  },
                  {
                    value: 'ATIVO',
                    label: 'Apenas Ativos'
                  },
                  {
                    value: 'INATIVO',
                    label: 'Apenas Inativos'
                  }
                ]}
              />

            </Form.Row>
            <div className='d-flex justify-content-between mb-4'>
              <Button
                onClick={() => { }}
                className="button-primary"
                type="submit"
              >
                <FaFilter className='mr-2' />
                {loading ? 'Buscando...' : 'Filtrar'}
              </Button>
              <div className='d-flex'>
                <Button
                  type="button"
                  onClick={() => handleGenerateReportFinancial(contracts, '', omitir_value, showData)}
                  className="button-secondary-outline mr-3"
                  disabled={!contracts.length}
                >
                  <FaFilePdf className='mr-2' size={20} />
                  Gerar PDF
                </Button>
                <Button
                  type="button"
                  onClick={() => handleGenerateExcelFile()}
                  className="button-secondary"
                  disabled={!contracts.length}
                >
                  <RiFileExcel2Fill className='mr-2' />
                  Gerar Excel
                </Button>
              </div>
            </div>
          </Form>


          <Row>
            <Col sm="4">
              <Card style={{ boxShadow: 'var(--strong-bs)', background: 'var(--gray-100', borderColor: 'var(--secondary-color)', color: 'var(--secondary-color)' }} className="mb-4">
                <Card.Body>
                  <Card.Title>Total</Card.Title>
                  <Card.Subtitle className="mb-2" style={{ fontSize: '36px', fontWeight: 'bold' }} >{contracts.length}</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="4">
              <Card style={{ boxShadow: 'var(--strong-bs)', background: 'var(--gray-100', borderColor: 'var(--primary-regular)', color: 'var(--primary-regular)' }} className="mb-4">
                <Card.Body>
                  <Card.Title>Ativos</Card.Title>
                  <Card.Subtitle className="mb-2" style={{ fontSize: '36px', fontWeight: 'bold' }} >
                    {contracts.filter(item => item.originalStatus === true).length}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="4">
              <Card style={{ boxShadow: 'var(--strong-bs)', background: 'var(--gray-100', borderColor: 'var(--secondary-color)', color: 'var(--secondary-color)' }} className="mb-4">
                <Card.Body>
                  <Card.Title>Inativos</Card.Title>
                  <Card.Subtitle className="mb-2" style={{ fontSize: '36px', fontWeight: 'bold' }} >
                    {contracts.filter(item => item.originalStatus === false).length}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <ComplexTable
            data={contracts}
            columns={[
              {
                dataField: 'count_date',
                text: 'Dias',
                sort: true,
                filter: false,
              },
              {
                dataField: 'isSuperior',
                text: 'Superior',
                sort: true,
                filter: false,
                formatter: (_, row) => <div>{row.isSuperior ? 'sim' : 'nao'}</div>
              },
               {
                dataField: 'integration_agent_value_company',
                text: 'Valor cheio',
                sort: true,
                filter: false,
                formatter: (_, row) => <div>{row.integration_agent_value_company?.toFixed(2)}</div>
              },
              {
                dataField: 'integration_agent_value_company_total',
                text: 'Valor cobrado agente ',
                sort: true,
                filter: false,
                formatter: (_, row) => <div>{row.integration_agent_value_company_total?.toFixed(2)}</div>
              },
              {
                dataField: 'institution_value_company',
                text: 'Valor cheio escola',
                sort: true,
                filter: false,
                formatter: (_, row) => <div>{row.institution_value_company?.toFixed(2)}</div>
              },
              {
                dataField: 'institution_value_company_total',
                text: 'Valor cobrado escola',
                sort: true,
                filter: false,
                formatter: (_, row) => <div>{row.institution_value_company_total?.toFixed(2)}</div>
              },
              {
                dataField: 'trainee_name',
                text: 'Estagiário',
                sort: true,
                filter: false,
              },

              {
                dataField: 'company_name',
                text: 'Empresa',
                sort: true,
                filter: false,
              },


              {
                dataField: 'start_validity',
                text: 'Data do cadastro',
                sort: false,
                filter: false,
              },
              {
                dataField: 'date_shutdown',
                text: 'Data de desligamento',
                sort: false,
                filter: false,
              },
              {
                dataField: 'status',
                text: 'Status',
                sort: true,
                filter: false,
              },
            ]}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default FinancialReport;
