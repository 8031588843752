
import pdfMake from 'pdfmake/build/pdfmake';
// eslint-disable-next-line import/no-extraneous-dependencies
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { format, addMonths, getDate, differenceInDays, } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const handleRange = (days) => {
    if (days < 15) return 0;
    else if (days < 45) return 1;
    else if (days < 75) return 2;
    else if (days < 105) return 3;
    else if (days < 135) return 4;
    else if (days < 165) return 5;
    else if (days < 195) return 6;
    else if (days < 225) return 7;
    else if (days < 255) return 8;
    else if (days < 285) return 9;
    else if (days < 315) return 10;
    else if (days < 345) return 11;
    else return 12; // Para todos os casos acima de 345 dias
  };

const handleGenerateTermQuitacao = (data) => {
    const date_shutdown_format = format(data.date_shutdown, "dd'/'MM'/'yyyy", { locale: ptBr });

    const dias = format(data.date_shutdown, "dd", { locale: ptBr });
    const mes = format(data.date_shutdown, "MMMM", { locale: ptBr });
    const ano = format(data.date_shutdown, "yyyy", { locale: ptBr });

    const diasTrabalhados = getDate(data.date_shutdown)

   const diferencaEmDias =  differenceInDays(new Date(data.date_shutdown), new Date(data.start_validity))
   
    

    const intervalorMesesCount = handleRange(diferencaEmDias)

    const valorProporcionalDescanso = data.total_value_desligamento / 12 * intervalorMesesCount
    
    const valorProporcionalDiasTrabalhados = data.total_value_desligamento / 30 * diasTrabalhados

    const valoTotalDesligamento = valorProporcionalDescanso + valorProporcionalDiasTrabalhados

    const current_date = format(data.date_shutdown, "dd 'de' MMMM 'de' yyyy", { locale: ptBr });
    const docDefinition =

        [


            { text: 'TERMO DE PAGAMENTO E QUITAÇÃO\n', bold: true, alignment: 'center', fontSize: 14, margin: [0, 0, 0, 10] },

            {
                table: {
                    widths: ['*', '*'],
                    body: [

                        [`NOME: ${data?.trainee?.name}`, `CPF:  ${data?.trainee.cpf}`],
                        [`EMPRESA: ${data?.company?.company_name}`, `Empresa:  ${data?.company?.cnpj}`]
                    ]
                },

            },

            {
                text: [
                    'Por este acordo celebrado, referente ao período de serviços prestados na modalidade de estágio extracurricular.',
                ], margin: [20, 10, 20, 10], alignment: 'center',

            },
            {
                text: [
                    'Período de estágio:',
                    { text: ` ${data.start_validity_formatted} a ${date_shutdown_format}.\n`, bold: true },
                ], margin: [20, 10, 20, 20], alignment: 'center',
            },




            {
                text: [
                    `Período de meses do descanso remunerado: ${intervalorMesesCount}/12 avos.`,

                ], alignment: 'justify',
            },

            {
                text: [
                    `Valor proporcional do descanso remunerado: R$ ${valorProporcionalDescanso.toFixed(2).toString().replace(".", ",")}.`,

                ], alignment: 'justify',
            },

            {
                text: [
                    { text: `Dias de estágio trabalhados (proporcional a) ${dias} dias, referente ao mês de ${mes} de ${ano}`, },
                ], alignment: 'justify',
            },

            {
                text: [
                    { text: `Valor proporcional da bolsa estágio: R$ ${valorProporcionalDiasTrabalhados.toFixed(2).toString().replace(".", ",")}.\n `, },
                ], alignment: 'justify',
            },

            {
                text: [
                    { text: `Total de valores a receber R$ ${valoTotalDesligamento.toFixed(2).toString().replace(".", ",")}.`, },
                ], margin: [20, 10, 20, 20], alignment: 'center',
            },

            {
                text: [
                    { text: `Eu, ${data.trainee?.name}, declaro que recebi a citada quantia acima, no valor de R$ ${valoTotalDesligamento.toFixed(2).toString().replace(".", ",")}, referente aos serviços prestados na modalidade de estágio no periodo de ${data.start_validity_formatted} a ${date_shutdown_format}, dando por este termo a ${data.company?.company_name}, plena, geral e irrevogável quitação para nada mais exigir, respeitando ao objetivo da presente quitação, seja a que título for. `, },
                ], margin: [20, 10, 20, 20], alignment: 'justify',
            },

            {
                text: [
                    { text: `E, para constar, foi lavrado este termo que será assinado por ambas as partes. \n`, },
                ], margin: [20, 10, 20, 20], alignment: 'justify',
            },

            {
                text: [
                    `Fortaleza/Ce, ${current_date} \n\n\n`,
                ], margin: [20, 10, 20, 20], alignment: 'right',
            },
            {
                columns: [
                    {
                        width: '*',
                        text: [
                            '___________________________________________________\n',
                            `ESTAGIÁRIO \n`,
                            `${data.trainee?.name}\n`,
                            `CPF: ${data.trainee?.cpf}\n\n\n\n`,
                        ], alignment: 'center',
                    },
                    {
                        width: '*',
                        text: [
                            '___________________________________________________\n',
                            `UNIDADE CONCEDENTE \n`,
                            `${data.company?.company_name}\n`,
                            `CNPJ: ${data.company?.cnpj}\n\n\n\n`,
                        ], alignment: 'center',

                    },
                ]
            },

            {
                columns: [

                ]
            },


        ]

   return docDefinition
}

export const handleGenerateTraineeRenovacao = (data) => {


    const definicaoTermoDeQuitacao = handleGenerateTermQuitacao(data)
    

    const isMaior = data?.trainee?.age >= 18 ? {} : {
        columns: [

            {
                width: '*',
                text: [
                    '______________________________________________________________________________________\n',
                    `Assinatura do Responsável\n
            `,
                ], alignment: 'center', pageBreak: 'after',

            },
        ]
    };

    const current_date = format(data.start_validity, "dd 'de' MMMM 'de' yyyy", { locale: ptBr });


    const end_validity2 = format(addMonths(data.end_validity_date, 13), "dd'/'MM'/'yyyy");
    const start_validity2 = format(addMonths(data.start_validity, 13), "dd'/'MM'/'yyyy");

    const docDefinition = {
        pageSize: 'A4',
        pageMargins: [40, 66, 40, 20],


        content: [


            { text: 'TERMO ADITIVO DE RENOVAÇÃO\n\n', alignment: 'center', fontSize: 15, margin: [0, 0, 0, 5], bold: true },
            { text: `Aditivo ao instrumento jurídico de Termo de Compromisso entre o(a) Estagiário(a): ${data?.trainee?.name}, portador(a) do CPF: ${data?.trainee.cpf} e Unidade Concedente ${data.company.company_name}, CNPJ: ${data.company.cnpj} datado em ${data.start_validity_formatted}, que vem retificar:`, alignment: 'justify', margin: [0, 0, 0, 5] },

            {
                text: [
                    { text: '\nPRORROGAÇÃO DE ESTÁGIO.\n\n1. Prorrogação de Estágio em vigência deste Instrumento: \n ', bold: true, },
                    `A partir de ${data.end_validity}, foi realizada a renovação do termo de compromisso de estágio, iniciando após o período de descanso. Ficando assim, tempo de vigência do termo de compromisso de estágio de data do novo contrato de ${start_validity2} a ${end_validity2} de acordo com a lei 11.788/08.\n\n`,
                    { text: 'As demais cláusulas e/ou conteúdos continuam inalteradas. \n\n', },
                    { text: 'Para produzir os fins de direito, as partes assinam o presente Instrumento. \n\n', },

                ]
            },


            {
                text: [
                    `Fortaleza/Ce, ${current_date}\n\n`,
                ], margin: [20, 10, 20, 20], alignment: 'right',
            },
            {
                columns: [
                    {
                        width: '*',
                        text: [
                            '________________________________________________\n',
                            'ESTAGIÁRIO\n',
                            `${data.trainee?.name}\n`,
                            `CPF: ${data.trainee?.cpf}\n\n\n\n`,
                        ], alignment: 'center',

                    },
                    {
                        width: '*',
                        text: [
                            '_________________________________________________\n',
                            'UNIDADE CONCEDENTE\n',
                            `${data.company?.company_name}\n`,
                            `CNPJ: ${data.company?.cnpj}\n\n\n\n`,
                        ], alignment: 'center',
                        pageBreak: 'after',

                    },
                ]
            },

            {
                columns: [

                ]
            },
            isMaior,

            /* FIM */

            ...definicaoTermoDeQuitacao

        ], defaultStyle: {
            fontSize: 11,
            margin: [20, 50]
        }
    };
    pdfMake.createPdf(docDefinition).open();
}
