import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Importações do bootstrap
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { FaFileAlt } from "react-icons/fa";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaFileInvoice } from "react-icons/fa";
// Meus componentes
import ComplexTable from '../../../components/ComplexTable';

//services
import api from '../../../services/axios';
import Icon from '../../../components/icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { handleGenerateContractCompanyTwo, handleGenerateTermoAnexo, handleGenerateTermoParceriaEscolaEmpresa } from 'src/screens/Relatorios/templates/report';
import { toast } from 'react-toastify';

const EmpresasList: React.FC = () => {
  const history = useHistory();

  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    api.get('companies')
      .then((response) => {
        setCompanies(response.data);
      })
  }, []);


  const handleAccessCredentials = (id: number) => {
    api.post('/companies/access', { company_id: id })
      .then((response) => {
        toast.warning(response.data.message)
      })
  }

  const handleExportClick = () => {
    api.get('companies/export', { responseType: 'blob' })
      .then(response => {
        // Cria um URL temporário para o arquivo CSV
        const url = URL.createObjectURL(response.data);

        // Cria um link de download para o arquivo CSV
        const link = document.createElement('a');
        link.href = url;
        link.download = 'empresas.csv';
        link.click();

        // Libera o URL temporário
        URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Erro ao exportar CSV:', error);
      });
  };

  return (
    <Container fluid>
      <Card style={{ boxShadow: 'var(--card-bs)' }}>
        <Card.Header className='d-flex justify-content-between align-items-center color-secondary' as="h5">
          <div>
            <i className="bi bi-stack mr-2"></i>
            Empresas
          </div>
          <div>
            <Button
              onClick={() => history.push('/empresas/novo')}
              className="button-primary mr-4"
            >
              <i className="bi bi-plus-lg mr-2"></i>
              Nova empresa
            </Button>
            <Button
              variant="primary"
              onClick={handleExportClick}
              className=" button-secondary "

            >
              <i className="bi bi-upload mr-2"></i>
              Exportar
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <ComplexTable
            data={companies}
            columns={[
              {
                dataField: '__meta__.qtd_contracts_actives',
                text: 'Ordem',
                sort: true,
                filter: false,
                headerStyle: {
                  width: '2%',
                },
                formatter: (_, row) => (
                  <OverlayTrigger
                    key="edit"
                    placement="right"
                    overlay={
                      <Tooltip id={`tooltip-right`}>
                        {Number(row.__meta__.qtd_contracts_actives) > 0 ? 'Estagiários ativos' : 'Sem estagiários'}
                      </Tooltip>
                    }
                  >
                    <div style={{ height: '30px', color: '#FFF', fontWeight: 'bold', borderRadius: '50px', width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: Number(row.__meta__.qtd_contracts_actives) > 0 ? 'var(--green-regular)' : 'var(--red-regular)' }}>{row.__meta__.qtd_contracts_actives}</div>
                  </OverlayTrigger>

                )
              },
              {
                dataField: 'amount_clt',
                text: 'Clt',
                sort: false,
                filter: true,
                headerStyle: {
                  width: '2%',
                },
                formatter: (_, row) => (
                  <span> {row.amount_clt ? `${row.amount_clt}/${(row.amount_clt / 100) * 20}` : 'N/D'}</span>
                )
              },
              {
                dataField: 'city',
                text: 'Cid.',
                sort: false,
                filter: true,
                headerStyle: {
                  width: '2%',
                },
              },
              {
                dataField: 'cnpj',
                text: 'CNPJ',
                sort: true,
                filter: true,
              },
              {
                dataField: 'company_name',
                text: ' Razão social',
                sort: true,
                filter: true,
              },
              {
                dataField: 'fantasy_name',
                text: 'Nome Fantasia',
                sort: true,
                filter: true,
              },
              {
                dataField: 'rh_analyst',
                text: 'E-mail RH',
                sort: true,
                filter: true,
              },
              {
                dataField: 'supervisor',
                text: 'E-mail Financeiro',
                sort: true,
                filter: true,
              },


              {
                dataField: 'id',
                text: 'Ações',
                sort: false,
                headerStyle: {
                  width: '15%',
                },
                formatter: (_, row) => (
                  <div>

                    <OverlayTrigger
                      key="supervisores"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          Adicionar Supervisor
                        </Tooltip>
                      }
                    >
                      <Icon
                        icon="FaPersonBooth"
                        className="mr-4 color-secondary interaction"
                        size={25}
                        onClick={() => history.push(`/empresas/supervisores/${row.id}`)}
                      />

                    </OverlayTrigger>

                    <OverlayTrigger
                      key="term_de_parceria_escola_empresa"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          Termo de convênio de parceira escola e empresa
                        </Tooltip>
                      }
                    >
                      <FaFileAlt onClick={() => handleGenerateTermoParceriaEscolaEmpresa(row)} className="mr-4 color-secondary interaction interaction" size={25} />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="term_de_parceria_escola_empresa_anexo"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          Anexo - Termo de Convênio
                        </Tooltip>
                      }
                    >
                      <FaFileInvoiceDollar size={25} onClick={() => handleGenerateTermoAnexo(row)} className="mr-4 color-secondary interaction" />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="term_de_parceria_estagio"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          Termo de Dr Estagio
                        </Tooltip>
                      }
                    >
                      <FaFileInvoice size={25} onClick={() => handleGenerateContractCompanyTwo(row)} className="mr-4 color-secondary interaction" />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="edit"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          Editar Empresa
                        </Tooltip>
                      }
                    >
                      <Icon icon="FaEdit" className="mr-4 color-secondary interaction" size={25} onClick={() => history.push(`empresas/${row.id}`)} />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="documents"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          Documentos da empresa
                        </Tooltip>
                      }
                    >
                      <Icon icon="FaFolder" size={25} onClick={() => history.push(`empresas/documentos/${row.id}`, { name: row.company_name })} className='color-secondary interaction' />
                    </OverlayTrigger>

                    <OverlayTrigger
                      key="credentials"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          Disponibilizar credenciais
                        </Tooltip>
                      }
                    >
                      <Icon icon="FaIdCard" className="ml-4 color-secondary interaction" size={25} onClick={() => handleAccessCredentials(row.id)} />
                    </OverlayTrigger>
                  </div>
                )
              },
            ]}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EmpresasList;
