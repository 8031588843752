import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Tooltip } from 'recharts';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { format, addMonths, subMonths } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import api from '../../services/axios';

import './style.css';
import ProgressPanel from './components/ProgressPanel';
import PresentationScreen from './components/PresentationScreen';
import FraseDoDia from './components/FraseDodia';

const COLORS = ['#FF6384', '#36A2EB', '#28a745', '#fd7e14', '#dc3545', '#343a40', '#20c997', '#6610f2'];

const Dashboard: React.FC = () => {
  const [showPresentation, setShowPresentation] = useState(false);
  const [customMessages, setCustomMessages] = useState({
    inactives_contracts_same_month_year_message: '',
    actives_contracts_same_month_year_message: '',
    renewal_contracts_same_moth_year_message: ''
  })

  useEffect(() => {
    const presentationSeen = localStorage.getItem('presentationSeen') === 'false';
    if (presentationSeen) {
      setShowPresentation(false);
    }
  }, []);

  const handleClosePresentation = () => {
    setShowPresentation(false);
  };

  const [resume, setResume] = useState({
    total_contracts: 0,
    shutdown_contracts: 0,
    actives_contracts: 0,
    total_trainees: 0,

    total_companies: 0,
    active_companies: 0,
    categories_companies_actives: {
      '5': 0,
      '10': 0,
      '15': 0,
      '20': 0,
      '20+': 0
    },
    total_trainees_abandonments: 0,
    groups_reasons_shutdowns: [],
    categories_reasons_group: {
      '15': 0,
      '30': 0,
      '60': 0,
      '90': 0,
      '90+': 0
    }
  });

  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());

  const monthName = useMemo(() => format(currentMonth, "MMMM 'de' yyyy", { locale: ptBr }).toUpperCase(), [currentMonth]);

  const handleAddMonth = useCallback(() => setCurrentMonth(addMonths(currentMonth, 1)), [currentMonth]);
  const handleSubMonth = useCallback(() => setCurrentMonth(subMonths(currentMonth, 1)), [currentMonth]);

  const [companiesByState, setCompaniesByState] = useState([])
  const [traineesByState, setTraineesByState] = useState([])

  useEffect(() => {
    const year = format(currentMonth, "yyyy");
    const month = format(currentMonth, "MM");

    api.get(`dashboard?year=${year}&month=${month}`)
      .then((response) => {

        setCompaniesByState(response.data.total_companies_by_state)
        setTraineesByState(response.data.total_trainees_by_state)

        setResume({
          total_trainees: Number(response.data.total_trainees),
          total_companies: Number(response.data.total_companies),
          active_companies: Number(response.data.active_companies),
       
          categories_companies_actives: response.data.categories_companies_actives,
          total_contracts: Number(response.data.total_contracts),
          shutdown_contracts: Number(response.data.shutdown_contracts),
          actives_contracts: Number(response.data.actives_contracts),
          total_trainees_abandonments: Number(response.data.total_trainees_abandonments || 0),
          groups_reasons_shutdowns: response.data.groups_reasons_shutdowns.map(item => ({
            name: item.reason_shutdown,
            value: Number(item.qtd)
          })),
          categories_reasons_group: response.data.categories_reasons_group,
        })

        setCustomMessages({
          inactives_contracts_same_month_year_message: response.data.inactives_contracts_same_month_year,
          actives_contracts_same_month_year_message: response.data.actives_contracts_same_month_year,
          renewal_contracts_same_moth_year_message: response.data.renewal_contracts_same_moth_year_message
        })

      })
      .catch(() => toast.error('Não conseguimos recuperar as informações'));
  }, [currentMonth]);

  const labels = [
    { name: 'Contratos desligados', value: resume.shutdown_contracts },
    { name: `Novos Contratos (${customMessages.actives_contracts_same_month_year_message} Ativos, ${customMessages.inactives_contracts_same_month_year_message} desligados),  ${customMessages.renewal_contracts_same_moth_year_message} Renovados`, value: resume.actives_contracts },
  ];

  const labelsGroup = [
    { name: 'Até 15 dias', value: resume.categories_reasons_group['15'] },
    { name: 'Até 30 dias', value: resume.categories_reasons_group['30'] },
    { name: 'Até 60 dias', value: resume.categories_reasons_group['60'] },
    { name: 'Até 90 dias', value: resume.categories_reasons_group['90'] },
    { name: 'Acima de 90 dias', value: resume.categories_reasons_group['90+'] },
  ];

  const labelsGroupActiveCompanies = [
    { name: 'Até 5 ', value: resume.categories_companies_actives['5'] },
    { name: 'Até 10', value: resume.categories_companies_actives['10'] },
    { name: 'Até 15', value: resume.categories_companies_actives['15'] },
    { name: 'Até 20', value: resume.categories_companies_actives['20'] },
    { name: 'Acima de 20', value: resume.categories_companies_actives['20+'] },
  ];

  return (
    <>
      {showPresentation && <PresentationScreen onClose={handleClosePresentation} />}
      <ProgressPanel totalContracts={resume.total_contracts} />
      <FraseDoDia />
      <Container fluid className={`dashboard-content ${showPresentation ? 'blurred' : ''}`}>
        <Card className='overflow-hidden mb-4'>
          <Card.Body className="d-flex justify-content-between align-items-center">

            <div>
              <Button onClick={handleSubMonth} className='month-directions-icons float-left'>
                <i className="bi bi-arrow-left-square-fill mr-2"></i>
                <p>Anterior</p>
              </Button>
            </div>

            <div style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold', display: 'flex', color: 'var(--secondary-color)' }}>
              <i style={{ color: 'var(--secondary-color)' }} className="bi bi-calendar-fill mr-2 fs-2"></i>
              {monthName}
            </div>

            <div>
              <Button onClick={handleAddMonth} className='month-directions-icons float-right'>
                <p>Próximo</p>
                <i className="bi bi-arrow-right-square-fill ml-2"></i>
              </Button>
            </div>

          </Card.Body>
          <Card.Footer style={{ background: 'var(--gray-100)' }}>
            <Row>
              <Col sm='3' className='w-100 text-center'>
                <Card style={{ borderColor: 'var(--primary-regular)', boxShadow: 'var(--strong-bs)' }}>
                  <Card.Body>
                    <Card.Title>Total</Card.Title>
                    <Card.Subtitle style={{ fontSize: '36px', fontWeight: 'bold', color: 'var(--primary-regular)' }}>{resume.total_contracts}</Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm='3' className='w-100 text-center'>
                <Card style={{ borderColor: 'var(--primary-regular)', boxShadow: 'var(--strong-bs)' }}>
                  <Card.Body>
                    <Card.Title>Total de cadastros</Card.Title>
                    <Card.Subtitle style={{ fontSize: '36px', fontWeight: 'bold', color: 'var(--primary-regular)' }}>{resume.total_trainees}</Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm='3' className='w-100 text-center'>
                <Card style={{ borderColor: 'var(--primary-regular)', boxShadow: 'var(--strong-bs)' }}>
                  <Card.Body>
                    <Card.Title>Total de empresas</Card.Title>
                    <Card.Subtitle style={{ fontSize: '36px', fontWeight: 'bold', color: 'var(--primary-regular)' }}>{resume.total_companies}</Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm='3' className='w-100 text-center'>
                <Card style={{ borderColor: 'var(--primary-regular)', boxShadow: 'var(--strong-bs)' }}>
                  <Card.Body>
                    <Card.Title>Empresas Ativas</Card.Title>
                    <Card.Subtitle style={{ fontSize: '36px', fontWeight: 'bold', color: 'var(--primary-regular)' }}>{resume.active_companies}</Card.Subtitle>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Container>

      <Container fluid>
        <Row>

          <Col sm={12}>
            <Row>
              <Col className='mb-4' sm="4">
                <Card style={{ boxShadow: 'var(--card-bs)', overflow: 'hidden', height: '100%' }}>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Quantidade de contratos feitos e desligados
                    </Card.Title>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart >
                        <Pie
                          dataKey="value"
                          isAnimationActive={true}
                          data={labels}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        >
                          {labels.map((_, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
              <Col className='mb-4' sm="4">
                <Card style={{ boxShadow: 'var(--card-bs)', overflow: 'hidden', height: '100%' }}>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Motivo de desligamentos
                    </Card.Title>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart >
                        <Pie
                          dataKey="value"
                          isAnimationActive={true}
                          data={resume.groups_reasons_shutdowns}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        >
                          {resume.groups_reasons_shutdowns.map((_, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>

              <Col className='mb-4' sm="4">
                <Card style={{ boxShadow: 'var(--card-bs)', overflow: 'hidden', height: '100%' }}>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Desligados por iniciativa do estagiário
                    </Card.Title>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart >
                        <Pie
                          dataKey="value"
                          isAnimationActive={true}
                          data={labelsGroup}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        >
                          {resume.groups_reasons_shutdowns.map((_, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
              <Col className='mb-4' sm="4">
                <Card style={{ boxShadow: 'var(--card-bs)', overflow: 'hidden', height: '100%' }}>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Estágiários Ativos Por Estado
                    </Card.Title>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart >
                        <Pie
                          dataKey="value"
                          isAnimationActive={true}
                          data={traineesByState}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        >
                          {labels.map((_, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>

              <Col className='mb-4' sm="4">
                <Card style={{ boxShadow: 'var(--card-bs)', overflow: 'hidden', height: '100%' }}>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Empresas Ativas Por Estado
                    </Card.Title>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart >
                        <Pie
                          dataKey="value"
                          isAnimationActive={true}
                          data={companiesByState}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        >
                          {companiesByState.map((_, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>

              <Col className='mb-4' sm="4">
                <Card style={{ boxShadow: 'var(--card-bs)', overflow: 'hidden', height: '100%' }}>
                  <Card.Body>
                    <Card.Title className="text-center">
                      Quantidade de estagiários por CNPJ
                    </Card.Title>
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart >
                        <Pie
                          dataKey="value"
                          isAnimationActive={true}
                          data={labelsGroupActiveCompanies}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#8884d8"
                          label
                        >
                          {resume.groups_reasons_shutdowns.map((_, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;